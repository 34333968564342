<template>
  <svg
    width="125"
    height="77"
    viewBox="0 0 125 77"
    fill="none"
    class="team-group-svg"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.7959 29.8892V31.4451H41.3366C42.7854 28.4975 45.8114 26.4635 49.3227 26.4635C52.834 26.4635 55.8601 28.4975 57.3088 31.4451H59.8424V29.8892C56.1027 28.8401 53.3622 25.4073 53.3622 21.3322C53.3622 20.5328 53.4692 19.7549 53.669 19.0127H44.9764C45.1762 19.7478 45.2833 20.5257 45.2833 21.3322C45.2833 25.4073 42.5427 28.8401 38.803 29.8892H38.7959Z"
      :class="colorForPos(1)"
    />
    <path
      d="M49.3225 14.5948C53.3477 14.5948 56.6164 11.319 56.6164 7.30098C56.6164 3.28294 53.3406 0 49.3225 0C45.3045 0 42.0287 3.2758 42.0287 7.30098C42.0287 11.3261 45.3045 14.5948 49.3225 14.5948Z"
      :class="colorForPos(1)"
    />
    <path
      d="M64.6597 29.8892V31.4451H67.1932C68.642 28.4975 71.6752 26.4635 75.1794 26.4635C78.6835 26.4635 81.7238 28.4975 83.1655 31.4451H85.699V29.8892C81.9593 28.8401 79.2188 25.4073 79.2188 21.3322C79.2188 20.5328 79.3259 19.7549 79.5257 19.0127H70.833C71.0329 19.7478 71.1399 20.5257 71.1399 21.3322C71.1399 25.4073 68.3994 28.8401 64.6597 29.8892Z"
      :class="colorForPos(5)"
    />
    <path
      d="M75.1795 14.5948C79.2046 14.5948 82.4804 11.319 82.4804 7.30098C82.4804 3.28294 79.2118 0 75.1795 0C71.1471 0 67.8856 3.2758 67.8856 7.30098C67.8856 11.3261 71.1614 14.5948 75.1795 14.5948Z"
      :class="colorForPos(5)"
    />
    <path
      d="M46.9105 45.4834V43.9276C43.5276 42.9784 40.9584 40.0737 40.5016 36.5053C40.4659 36.2412 40.4445 35.9772 40.4374 35.706C40.4374 35.5918 40.4302 35.4776 40.4302 35.3634C40.4302 35.2135 40.4302 35.0637 40.4445 34.9067C40.473 34.2643 40.573 33.6434 40.7371 33.0439H32.0445C32.2443 33.779 32.3513 34.557 32.3513 35.3634C32.3513 39.4385 29.6108 42.8714 25.8711 43.9276V45.4834H28.4047C29.8534 42.5359 32.8795 40.5019 36.3908 40.5019C39.9021 40.5019 42.9281 42.5288 44.3769 45.4834H46.9176H46.9105Z"
      :class="colorForPos(2)"
    />
    <path
      d="M36.3836 28.6261C36.662 28.6261 36.9332 28.6118 37.1972 28.5832C37.4684 28.5547 37.7325 28.5047 37.9966 28.4476C38.2678 28.3906 38.5318 28.3049 38.7959 28.2193C41.6435 27.2201 43.6846 24.5081 43.6846 21.3322C43.6846 20.5329 43.5561 19.7621 43.3206 19.0413C43.235 18.7772 43.1279 18.5203 43.0137 18.2705C42.8996 18.0136 42.764 17.7709 42.6212 17.5283C41.3366 15.4301 39.0243 14.0312 36.3908 14.0312C32.3656 14.0312 29.0969 17.3071 29.0969 21.3251C29.0969 25.3431 32.3727 28.6189 36.3908 28.6189L36.3836 28.6261Z"
      :class="colorForPos(2)"
    />
    <path
      d="M72.7742 45.4834V43.9276C69.3914 42.9713 66.8221 40.0666 66.3654 36.5053C66.3297 36.2412 66.3083 35.9772 66.3011 35.706C66.3011 35.5918 66.294 35.4776 66.294 35.3634C66.294 35.2135 66.294 35.0637 66.3083 34.9067C66.3368 34.2643 66.4367 33.6434 66.6009 33.0439H57.9082C58.0724 33.6434 58.1723 34.2643 58.2008 34.9067C58.208 35.0565 58.2151 35.2064 58.2151 35.3634C58.2151 35.4776 58.2151 35.5918 58.208 35.706C58.2008 35.9772 58.1794 36.2412 58.1437 36.5053C57.687 40.0737 55.1249 42.9784 51.7349 43.9276V45.4834H54.2684C55.7172 42.5359 58.7504 40.5019 62.2546 40.5019C65.7587 40.5019 68.7919 42.5288 70.2407 45.4834H72.7742Z"
      :class="colorForPos(6)"
    />
    <path
      d="M55.6245 18.2702C55.5103 18.52 55.4032 18.7769 55.3176 19.041C55.0749 19.7618 54.9536 20.5255 54.9536 21.3319C54.9536 24.5078 56.9947 27.2198 59.8423 28.219C60.0993 28.3117 60.3705 28.3831 60.6417 28.4473C60.8986 28.5116 61.1698 28.5544 61.441 28.5901C61.7051 28.6186 61.9834 28.6329 62.2617 28.6329C62.5401 28.6329 62.8113 28.6186 63.0753 28.5901C63.3465 28.5615 63.6106 28.5116 63.8747 28.4545C64.1459 28.3974 64.4099 28.3117 64.674 28.2261C67.5216 27.2269 69.5627 24.515 69.5627 21.3391C69.5627 20.5397 69.4342 19.769 69.1987 19.0481C69.1131 18.7841 69.006 18.5272 68.8918 18.2774C68.7777 18.0204 68.6421 17.7778 68.4993 17.5351C67.2147 15.4369 64.9024 14.0381 62.2689 14.0381C59.6354 14.0381 57.323 15.4369 56.0384 17.5351C55.8885 17.7778 55.7529 18.0204 55.6387 18.2774L55.6245 18.2702Z"
      :class="colorForPos(6)"
    />
    <path
      d="M98.6381 45.4834V43.9276C94.8984 42.8785 92.1579 39.4457 92.1579 35.3634C92.1579 34.5641 92.2649 33.7862 92.4648 33.0439H83.7721C83.9363 33.6434 84.0362 34.2643 84.0647 34.9067C84.0719 35.0565 84.079 35.2064 84.079 35.3634C84.079 35.4776 84.079 35.5918 84.0719 35.706C84.0647 35.9772 84.0433 36.2412 84.0076 36.5053C83.5509 40.0666 80.9887 42.9784 77.5988 43.9276V45.4834H80.1323C81.5811 42.5359 84.6071 40.5019 88.1184 40.5019C91.6298 40.5019 94.6558 42.5288 96.1046 45.4834H98.6453H98.6381Z"
      :class="colorForPos(9)"
    />
    <path
      d="M81.4954 18.2702C81.3813 18.52 81.2742 18.7769 81.1886 19.041C80.9459 19.7618 80.8246 20.5255 80.8246 21.3319C80.8246 24.515 82.8729 27.2269 85.7133 28.219C85.9702 28.3117 86.2414 28.3831 86.5126 28.4473C86.7696 28.5044 87.0408 28.5544 87.312 28.5829C87.576 28.6115 87.8472 28.6258 88.1256 28.6258C92.1507 28.6258 95.4194 25.35 95.4194 21.3319C95.4194 17.3139 92.1436 14.0381 88.1256 14.0381C85.4921 14.0381 83.1797 15.4369 81.8951 17.5351C81.7524 17.7778 81.6168 18.0204 81.5026 18.2774L81.4954 18.2702Z"
      :class="colorForPos(9)"
    />
    <path
      d="M30.8739 59.5073H33.9785V57.9514C30.5885 57.0022 28.0192 54.0975 27.5696 50.5291C27.5339 50.2651 27.5125 50.001 27.5054 49.7298C27.5054 49.6156 27.4982 49.5014 27.4982 49.3944C27.4982 49.2445 27.4982 49.0875 27.5125 48.9376C27.541 48.3024 27.641 47.6744 27.8051 47.082H19.1124C19.3123 47.8171 19.4193 48.595 19.4193 49.4015C19.4193 53.4766 16.6788 56.9094 12.9391 57.9586V59.5144H16.0436C18.5486 59.5144 20.7325 60.9132 21.8459 62.9686H25.0788C26.1922 60.9132 28.3689 59.5144 30.8811 59.5144L30.8739 59.5073Z"
      :class="colorForPos(3)"
    />
    <path
      d="M23.4516 42.6566C23.73 42.6566 24.0012 42.6424 24.2724 42.6138C24.5436 42.5853 24.8076 42.5353 25.0717 42.4711C25.3429 42.414 25.6069 42.3355 25.871 42.2427C28.7186 41.2435 30.7597 38.5316 30.7597 35.3557C30.7597 34.5563 30.6313 33.7856 30.3886 33.0647C30.303 32.8007 30.2031 32.5438 30.0889 32.294C29.9747 32.037 29.8391 31.7944 29.6892 31.5517C28.4046 29.4535 26.0994 28.0547 23.4588 28.0547C19.4336 28.0547 16.1649 31.3305 16.1649 35.3485C16.1649 39.3666 19.4407 42.6495 23.4588 42.6495L23.4516 42.6566Z"
      :class="colorForPos(3)"
    />
    <path
      d="M56.7377 59.5073H59.8422V57.9514C56.4522 56.9951 53.883 54.0975 53.4334 50.5291C53.3977 50.2651 53.3763 50.001 53.3691 49.7298C53.3691 49.6156 53.362 49.5014 53.362 49.3944C53.362 49.2445 53.362 49.0875 53.3763 48.9376C53.4048 48.3024 53.5047 47.6744 53.6689 47.082H44.9762C45.1404 47.6815 45.2403 48.3024 45.2688 48.9376C45.276 49.0875 45.2831 49.2445 45.2831 49.3944C45.2831 49.5086 45.2831 49.6227 45.276 49.7298C45.2688 50.001 45.2474 50.2651 45.2117 50.5291C44.7621 54.0975 42.1929 57.0022 38.8029 57.9514V59.5073H41.9074C44.4124 59.5073 46.5963 60.9061 47.7096 62.9615H50.9355C52.0488 60.9061 54.2327 59.5073 56.7377 59.5073Z"
      :class="colorForPos(7)"
    />
    <path
      d="M47.7097 42.4782C47.9666 42.5353 48.2378 42.5853 48.509 42.6138C48.7731 42.6424 49.0443 42.6566 49.3226 42.6566C49.6009 42.6566 49.8721 42.6424 50.1362 42.6138C50.4074 42.5853 50.6715 42.5353 50.9355 42.4782C51.2067 42.4211 51.4708 42.3355 51.7348 42.2498C54.2327 41.372 56.1097 39.1739 56.5379 36.4976C56.5807 36.2335 56.6022 35.9694 56.6164 35.6982C56.6164 35.584 56.6236 35.4699 56.6236 35.3557C56.6236 35.2058 56.6236 35.0559 56.6093 34.8989C56.5736 34.2637 56.4523 33.65 56.2525 33.0647C56.1668 32.8007 56.0669 32.5438 55.9527 32.294C55.8385 32.037 55.7029 31.7944 55.5602 31.5517C54.2756 29.4535 51.9632 28.0547 49.3297 28.0547C46.6962 28.0547 44.3839 29.4535 43.0993 31.5517C42.9565 31.7944 42.8209 32.037 42.7067 32.294C42.5926 32.5438 42.4855 32.8007 42.407 33.0647C42.2143 33.65 42.0858 34.2637 42.0502 34.8989C42.0359 35.0488 42.0359 35.1987 42.0359 35.3557C42.0359 35.4699 42.0359 35.584 42.043 35.6982C42.0573 35.9694 42.0858 36.2335 42.1215 36.4976C42.5426 39.1739 44.4196 41.372 46.9246 42.2498C47.1815 42.3426 47.4527 42.4211 47.7239 42.4782H47.7097Z"
      :class="colorForPos(7)"
    />
    <path
      d="M82.6016 59.5073H85.7061V57.9514C82.3161 57.0022 79.7469 54.0975 79.2973 50.5291C79.2616 50.2651 79.2402 50.001 79.233 49.7298C79.233 49.6156 79.2259 49.5014 79.2259 49.3944C79.2259 49.2445 79.2259 49.0875 79.2402 48.9376C79.2687 48.3024 79.3686 47.6744 79.5328 47.082H70.8401C71.0043 47.6815 71.1042 48.3024 71.1327 48.9376C71.1399 49.0875 71.147 49.2445 71.147 49.3944C71.147 49.5086 71.147 49.6227 71.1399 49.7298C71.1327 50.001 71.1113 50.2651 71.0756 50.5291C70.626 54.0975 68.0567 57.0022 64.6667 57.9514V59.5073H67.7713C70.2763 59.5073 72.4602 60.9061 73.5735 62.9615H76.8065C77.9198 60.9061 80.0966 59.5073 82.6087 59.5073H82.6016Z"
      :class="colorForPos(10)"
    />
    <path
      d="M73.5736 42.4782C73.8305 42.5353 74.1017 42.5853 74.3729 42.6138C74.6369 42.6424 74.9081 42.6566 75.1865 42.6566C75.4648 42.6566 75.736 42.6424 76.0072 42.6138C76.2784 42.5853 76.5425 42.5353 76.8065 42.4711C77.0777 42.414 77.3418 42.3355 77.6059 42.2427C80.1038 41.3649 81.9807 39.1667 82.409 36.4904C82.4518 36.2264 82.4732 35.9623 82.4875 35.6911C82.4875 35.5769 82.4946 35.4627 82.4946 35.3485C82.4946 35.1987 82.4946 35.0488 82.4803 34.8918C82.4446 34.2566 82.3233 33.6428 82.1235 33.0576C82.0378 32.7935 81.9379 32.5366 81.8237 32.2868C81.7024 32.0299 81.5739 31.7801 81.4241 31.5446C80.1394 29.4535 77.8271 28.0547 75.1936 28.0547C72.5601 28.0547 70.2478 29.4535 68.9632 31.5517C68.8204 31.7944 68.6848 32.037 68.5706 32.294C68.4565 32.5438 68.3494 32.8007 68.2709 33.0647C68.0782 33.65 67.9497 34.2637 67.9141 34.8989C67.8998 35.0488 67.8998 35.1987 67.8998 35.3557C67.8998 35.4699 67.8998 35.584 67.9069 35.6982C67.9212 35.9694 67.9497 36.2335 67.9854 36.4976C68.4065 39.1739 70.2906 41.372 72.7885 42.2498C73.0454 42.3426 73.3166 42.414 73.5878 42.4782H73.5736Z"
      :class="colorForPos(10)"
    />
    <path
      d="M108.465 59.5066H111.57V57.9507C107.83 56.9016 105.09 53.4688 105.09 49.3937C105.09 48.5944 105.197 47.8164 105.397 47.0742H96.704C96.8681 47.6737 96.9681 48.2946 96.9966 48.9298C97.0037 49.0797 97.0109 49.2367 97.0109 49.3866C97.0109 49.5007 97.0109 49.6149 97.0037 49.722C96.9966 49.9932 96.9752 50.2572 96.9395 50.5213C96.4899 54.0897 93.9206 56.9944 90.5306 57.9436V59.4994H93.6352C96.1402 59.4994 98.3241 60.8983 99.4374 62.9537H102.663C103.777 60.8983 105.96 59.4994 108.465 59.4994V59.5066Z"
      :class="colorForPos(12)"
    />
    <path
      d="M94.4275 32.3015C94.3133 32.5513 94.2062 32.8082 94.1277 33.0723C93.8851 33.7931 93.7566 34.5638 93.7566 35.3632C93.7566 38.5462 95.8049 41.2582 98.6453 42.2574C98.9023 42.3501 99.1734 42.4215 99.4446 42.4857C99.7016 42.5428 99.9728 42.5928 100.244 42.6213C100.508 42.6499 100.779 42.6642 101.058 42.6642C105.083 42.6642 108.351 39.3883 108.351 35.3632C108.351 31.338 105.076 28.0693 101.058 28.0693C98.4241 28.0693 96.1117 29.4682 94.8271 31.5664C94.6844 31.809 94.5488 32.0517 94.4346 32.3086L94.4275 32.3015Z"
      :class="colorForPos(12)"
    />
    <path
      d="M46.3253 63.7604C46.1754 63.4749 45.997 63.2108 45.79 62.961C44.8765 61.8263 43.4705 61.1055 41.9004 61.1055H30.874C29.3039 61.1055 27.9051 61.8334 26.9844 62.961C26.7846 63.2108 26.6062 63.4749 26.4563 63.7604C26.3207 64.0173 26.2065 64.2814 26.1209 64.5597C25.9567 65.0521 25.8711 65.5731 25.8711 66.1155V76.992H46.9176V66.1155C46.9176 65.5731 46.832 65.045 46.6678 64.5597C46.5822 64.2814 46.468 64.0173 46.3324 63.7604H46.3253Z"
      :class="colorForPos(8)"
    />
    <path
      d="M43.0065 46.332C42.8924 46.0751 42.7568 45.8325 42.614 45.5898C41.3294 43.4916 39.0171 42.0928 36.3836 42.0928C33.7501 42.0928 31.4377 43.4916 30.1531 45.5898C30.0104 45.8325 29.8748 46.0751 29.7606 46.332C29.6464 46.5818 29.5393 46.8388 29.4608 47.1028C29.2681 47.688 29.1468 48.3018 29.104 48.9299C29.0897 49.0797 29.0897 49.2296 29.0897 49.3866C29.0897 49.5008 29.0897 49.615 29.0969 49.722C29.1111 49.9932 29.1397 50.2573 29.1754 50.5214C29.5964 53.1977 31.4734 55.3958 33.9785 56.2737C34.2354 56.3664 34.5066 56.4449 34.7778 56.502C35.0347 56.5591 35.3059 56.6091 35.5771 56.6376C35.8412 56.6662 36.1124 56.6805 36.3907 56.6805C36.669 56.6805 36.9402 56.6662 37.2043 56.6376C37.4755 56.6091 37.7396 56.5591 38.0036 56.502C38.2748 56.4449 38.5389 56.3593 38.8029 56.2737C41.3008 55.3958 43.1778 53.1977 43.606 50.5214C43.6489 50.2644 43.6703 49.9932 43.6845 49.722C43.6845 49.6079 43.6917 49.4937 43.6917 49.3866C43.6917 49.2296 43.6917 49.0797 43.6774 48.9299C43.6346 48.2947 43.5204 47.6809 43.3206 47.1028C43.2349 46.8388 43.135 46.5818 43.0208 46.332H43.0065Z"
      :class="colorForPos(8)"
    />
    <path
      d="M20.4542 63.7604C20.3043 63.4749 20.1259 63.2108 19.9189 62.961C19.0054 61.8263 17.5994 61.1055 16.0293 61.1055H5.01005C2.2481 61.1055 0 63.3536 0 66.1155V76.992H21.0465V66.1155C21.0465 65.5731 20.9609 65.045 20.7967 64.5597C20.7111 64.2814 20.5969 64.0173 20.4613 63.7604H20.4542Z"
      :class="colorForPos(4)"
    />
    <path
      d="M10.5269 56.6876C10.8052 56.6876 11.0764 56.6733 11.3405 56.6448C11.6117 56.6162 11.8757 56.5663 12.1398 56.5092C12.411 56.4521 12.6751 56.3736 12.9391 56.2808C15.7796 55.2816 17.8278 52.5696 17.8278 49.3938C17.8278 48.5944 17.6994 47.8236 17.4567 47.1028C17.3711 46.8388 17.2712 46.5818 17.157 46.332C17.0428 46.0751 16.9072 45.8325 16.7645 45.5898C15.4798 43.4916 13.1675 42.0928 10.534 42.0928C6.50884 42.0928 3.23303 45.3686 3.23303 49.3866C3.23303 53.4046 6.50884 56.6805 10.534 56.6805L10.5269 56.6876Z"
      :class="colorForPos(4)"
    />
    <path
      d="M72.1819 63.7604C72.032 63.4749 71.8536 63.2108 71.6466 62.961C70.7331 61.8263 69.3271 61.1055 67.757 61.1055H56.7306C55.1605 61.1055 53.7617 61.8334 52.8411 62.961C52.6412 63.2108 52.4557 63.4749 52.3058 63.7604C52.1702 64.0173 52.056 64.2814 51.9704 64.5597C51.8062 65.0521 51.7206 65.5731 51.7206 66.1155V76.992H72.7671V66.1155C72.7671 65.5731 72.6814 65.045 72.5173 64.5597C72.4317 64.2814 72.3175 64.0173 72.1819 63.7604Z"
      :class="colorForPos(11)"
    />
    <path
      d="M68.8776 46.332C68.7634 46.0751 68.6278 45.8325 68.485 45.5898C67.2004 43.4916 64.8881 42.0928 62.2546 42.0928C59.6211 42.0928 57.3088 43.4916 56.0241 45.5898C55.8743 45.8325 55.7387 46.0751 55.6245 46.332C55.5103 46.5818 55.4032 46.8388 55.3247 47.1028C55.132 47.688 55.0107 48.3018 54.9679 48.9299C54.9536 49.0797 54.9536 49.2296 54.9536 49.3866C54.9536 49.5008 54.9536 49.615 54.9608 49.722C54.975 49.9932 55.0036 50.2573 55.0393 50.5214C55.4603 53.1977 57.3373 55.3958 59.8423 56.2737C60.0993 56.3664 60.3705 56.4378 60.6417 56.502C60.8986 56.5663 61.1698 56.6091 61.441 56.6448C61.7051 56.6733 61.9834 56.6876 62.2617 56.6876C62.5401 56.6876 62.8113 56.6733 63.0753 56.6448C63.3465 56.6162 63.6106 56.5663 63.8747 56.5092C64.1459 56.4521 64.4099 56.3664 64.674 56.2808C67.1719 55.403 69.0489 53.2048 69.4771 50.5285C69.5199 50.2716 69.5413 50.0004 69.5556 49.7292C69.5556 49.615 69.5627 49.5008 69.5627 49.3938C69.5627 49.2367 69.5627 49.0869 69.5484 48.937C69.5056 48.3018 69.3914 47.6881 69.1916 47.11C69.1059 46.8459 69.006 46.589 68.8918 46.3392L68.8776 46.332Z"
      :class="colorForPos(11)"
    />
    <path
      d="M98.0529 63.7604C97.903 63.4749 97.7246 63.2108 97.5176 62.961C96.6041 61.8263 95.1982 61.1055 93.6281 61.1055H82.6017C81.0316 61.1055 79.6327 61.8334 78.7121 62.961C78.5123 63.2108 78.3338 63.4749 78.184 63.7604C78.0484 64.0173 77.9342 64.2814 77.8485 64.5597C77.6844 65.0521 77.5988 65.5731 77.5988 66.1155V76.992H98.6453V66.1155C98.6453 65.5731 98.5596 65.045 98.3955 64.5597C98.3098 64.2814 98.1956 64.0173 98.06 63.7604H98.0529Z"
      :class="colorForPos(13)"
    />
    <path
      d="M94.7342 46.332C94.62 46.0751 94.4844 45.8325 94.3417 45.5898C93.0571 43.4916 90.7447 42.0928 88.1112 42.0928C85.4777 42.0928 83.1654 43.4916 81.8808 45.5898C81.738 45.8325 81.6024 46.0751 81.4882 46.332C81.3741 46.5818 81.267 46.8388 81.1885 47.1028C80.9958 47.688 80.8745 48.3018 80.8317 48.9299C80.8174 49.0797 80.8174 49.2296 80.8174 49.3866C80.8174 49.5008 80.8174 49.615 80.8245 49.722C80.8388 49.9932 80.8673 50.2573 80.903 50.5214C81.3241 53.1977 83.2082 55.3958 85.7061 56.2737C85.963 56.3664 86.2342 56.4378 86.5054 56.502C86.7624 56.5591 87.0336 56.6091 87.3048 56.6376C87.5688 56.6662 87.84 56.6805 88.1184 56.6805C88.3967 56.6805 88.6679 56.6662 88.932 56.6376C89.2032 56.6091 89.4672 56.5591 89.7313 56.502C90.0025 56.4449 90.2665 56.3664 90.5306 56.2737C93.0285 55.3958 94.9055 53.1977 95.3337 50.5214C95.3765 50.2644 95.3979 49.9932 95.4122 49.722C95.4122 49.6079 95.4193 49.4937 95.4193 49.3866C95.4193 49.2296 95.4193 49.0797 95.4051 48.9299C95.3622 48.2947 95.2481 47.6809 95.0482 47.1028C94.9626 46.8388 94.8627 46.5818 94.7485 46.332H94.7342Z"
      :class="colorForPos(13)"
    />
    <path
      d="M119.492 61.1055H108.465C106.895 61.1055 105.497 61.8334 104.576 62.961C104.376 63.2108 104.191 63.4749 104.041 63.7604C103.905 64.0173 103.791 64.2814 103.705 64.5597C103.541 65.0521 103.455 65.5731 103.455 66.1155V76.992H124.502V66.1155C124.502 63.3536 122.254 61.1055 119.492 61.1055Z"
      :class="colorForPos(14)"
    />
    <path
      d="M107.352 46.3324C107.238 46.5822 107.131 46.8391 107.052 47.1032C106.81 47.824 106.681 48.5948 106.681 49.3941C106.681 52.57 108.722 55.282 111.57 56.2812C111.827 56.3739 112.098 56.4453 112.369 56.5095C112.626 56.5738 112.897 56.6166 113.169 56.6523C113.433 56.6808 113.711 56.6951 113.989 56.6951C118.015 56.6951 121.283 53.4193 121.283 49.4013C121.283 45.3832 118.007 42.1074 113.989 42.1074C111.356 42.1074 109.044 43.5062 107.759 45.5973C107.609 45.84 107.481 46.0898 107.359 46.3396L107.352 46.3324Z"
      :class="colorForPos(14)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    name: String,
    percentage: {
      default: 100,
      type: Number,
    },
  },
  computed: {
    color() {
      return this.name ? this.name : "gray";
    },
  },
  methods: {
    colorForPos(pos) {
      if (this.percentage === 100) return this.color;
      const pos_percentage = (pos / 14) * 100;
      return pos_percentage > this.percentage ? "gray" : this.color;
    },
  },
};
</script>

<style>
.team-group-svg path {
  fill: #747474;
  transition: fill 1s linear;
}
/* 
.team-group-svg path.green {
  fill: #95c93d;
} */

.team-group-svg path.yellow {
  fill: #ffde59;
}

/* .team-group-svg path.pink {
  fill: #d31c67;
} */

.team-group-svg path.purple {
  fill: #8c52ff;
}

.team-group-svg path.blue {
  fill: #1ec5e0;
}

.team-group-svg path.orange {
  fill: #fe7f30;
}
</style>
