import nb from "./nb.json";
import en from "./en.json";
import tr from "./tr.json";
import de from "./de.json";
import fr from "./fr.json";
import nl from "./nl.json";
import sp from "./sp.json";
import hu from "./hu.json";
import pl from "./pl.json";
import ru from "./ru.json";
import fl from "./fl.json";
import ro from "./ro.json";

export default {
  nb,
  en,
  tr,
  de,
  fr,
  nl,
  sp,
  hu,
  pl,
  ru,
  fl,
  ro,
};
